// Import Swiper React components
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./style.css";

export default class Carrossel extends React.Component {
  render() {
    return (
      <Swiper
        className="swiper-container h-38"
        modules={[Navigation, Pagination, Autoplay]}
        navigation={true}
        pagination={{ clickable: true }}
        autoplay={{ delay: 2000 }}
        spaceBetween={0}
        slidesPerView={2}
        /* onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)} */
      >
        <SwiperSlide className="slide-item slide-item1 mx-2 flex ">
          <div className="flex items-center justify-center h-32 w-48 py-4 px-4">
            <img
              className="object-contain"
              src={require("../../../assets/Logo_GrupoBerlim.png")}
              alt="Logo Grupo Berlim"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide-item">
          <div className="h-32 w-48 py-4 px-4 flex items-center justify-center">
            <img
              className="object-contain"
              src={require("../../../assets/Logo-BerlimDigital.png")}
              alt="Logo Berlim Digital"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide-item">
          <div className="h-32 w-48 py-4 px-4 flex items-center justify-center">
            <img
              className="object-contain"
              src={require("../../../assets/Logo-BResults.png")}
              alt="Logo BResults"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide-item slide-item2">
          <div className="h-32 w-48 py-4 px-4 flex items-center justify-center">
            <img
              className="object-contain"
              src={require("../../../assets/Logo-ROADMAPPS.png")}
              alt="Logo ROADMAPPS"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide-item">
          <div className="h-32 w-48 p-8 flex items-center justify-center">
            <img
              className="object-contain"
              src={require("../../../assets/orbit.png")}
              alt="Logo Orbit"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide-item">
          <div className="h-32 w-48 p-10 flex items-center justify-center">
            <img
              className="object-contain"
              src={require("../../../assets/bs.png")}
              alt="Logo BS Seguros"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide-item">
          <div className="h-32 w-48 p-10 flex items-center justify-center">
            <img
              className="object-contain"
              src={require("../../../assets/wipro.png")}
              alt="Logo Wipro"
            />
          </div>
        </SwiperSlide>
        {/* <SwiperSlide className="slide-item">
          <div className="h-32 w-48 py-4 px-4 flex items-center justify-center">
            <img
              className="object-contain"
              src={require("../../../assets/Logo-Jala.png")}
              alt="Jala"
            />
          </div>
        </SwiperSlide> */}
      </Swiper>
    );
  }
}
