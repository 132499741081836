import { Icon } from "@iconify/react";
import React, { useState } from "react";
import "../../styles/carroselPlanos.css";

import Foto1 from "../../../assets/FotoComentarios/Brenno.png";
import Foto2 from "../../../assets/FotoComentarios/Thais.png";
import Foto3 from "../../../assets/FotoComentarios/Klaus.png";
import Foto4 from "../../../assets/FotoComentarios/Leticia.png";
import Foto5 from "../../../assets/FotoComentarios/Roberto.png";

const ComentariosDesktop = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const cardsPerPage = 3;
  const items = [
    {
      foto: Foto1,
      description:
        "Ótimo espaço pra trabalhar com espaço climatizado. Durante as pausas fazer um networking, apreciar o Marco Zero, a vista do mar azul e a rua mais bonita do mundo, tudo ao mesmo tempo!",
      nome: "Brenno Calado",
      empresa: "Freelancer",
    },
    {
      foto: Foto3,
      description: "Lugar massa e com pessoas incríveis!",
      nome: "Klaus Piaia",
      empresa: "Empresário",
    },
    {
      foto: Foto5,
      description:
        "Ótimo espaço, funcional, bem estruturado (Wi-Fi, banheiros incríveis, excelente área comum). Atendimento incrível e uma equipe de gestão pronta pra te ajudar se precisar de uma sala de reunião ou um espaço extra de urgência",
      nome: "Roberto Silva",
      empresa: "Kalea",
    },
    {
      foto: Foto2,
      description:
        "Lugar legal para realização de cursos e eventos. Fiz um evento aqui e tive todo o suporte da equipe, muito bom.",
      nome: "Thaís Rodrigues ",
      empresa: "Orientadora da Berlim Digital",
    },
    {
      foto: Foto4,
      description:
        "O melhor espaço de coworking do Recife, sem dúvida. Super bem localizado, na rua mais linda do mundo (invejosos dirão que é a terceira, porém pra mim é a primeira e pronto), e além de lindo, super confortável e instagramável, ainda dá pra comprar (lá dentro mesmo) várias bebidas e comidinhas... O pessoal que cuida do espaço tbm é sensacional!! Adoro esse lugar, melhor cantinho do Recife Antigo.",
      nome: "Leticia Andrade",
      empresa: "Desenvolvedora da BResults",
    },
  ];

  const totalPages = Math.ceil(items.length / cardsPerPage);

  const nextSlide = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages - 1 ? 0 : prevPage + 1
    );
  };

  const prevSlide = () => {
    setCurrentPage((prevPage) =>
      prevPage === 0 ? totalPages - 1 : prevPage - 1
    );
  };

  const renderCards = () => {
    const startIndex = currentPage * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;

    return items.slice(startIndex, endIndex).map((item, index) => (
      <div
        key={index}
        className={`carousel__slide px-2 pb-3 flex flex-col justify-between items-center shadow-md mx-5 flex-1 max-w-sm`}
      >
        <div className="overflow-clip rounded-full mb-3">
          <img className="object-cover w-48 h-48" src={item.foto} alt="Foto" />
        </div>
        <p className="mt-3 text-center text-white text-base/none mb-4">
          {item.description}
        </p>
        <div className="flex flex-col my-2 items-center justify-center ">
          <p className="text-2xl/tight text-white capitalize font-bold mb-2">
            {item.nome}
          </p>
          <p className="text-xl/tight text-white font-medium">{item.empresa}</p>
        </div>
      </div>
    ));
  };

  return (
    <div className="container-carrossel bg-black relative py-10 px-24">
      <div className="carousel text-roxoEscuro relative mx-8 ">
        <button
          className={`carousel__arrow carousel__arrow--prev absolute top-20 -left-9 cursor-pointer`}
          onClick={prevSlide}
        >
          <Icon
            icon="bytesize:chevron-left"
            width={60}
            height={60}
            color="white"
          />
        </button>

        <div className="flex justify-center">{renderCards()}</div>

        <button
          className={`carousel__arrow carousel__arrow--prev absolute top-20 -right-9 cursor-pointer`}
          onClick={nextSlide}
        >
          <Icon
            icon="bytesize:chevron-right"
            width={60}
            height={60}
            color="white"
          />
        </button>
      </div>
    </div>
  );
};

export default ComentariosDesktop;
